{
  "version": "17.0.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "tail -f /dev/null",
    "start:dev:test": "ng serve --c=test --hmr --open",
    "start:dev:acc": "ng serve --c=acc --hmr --open",
    "start:dev:aot": "node --max_old_space_size=8192 node_modules/@angular/cli/bin/ng s -c=test --open --aot",
    "dockerserve": "ng serve --proxy-config proxy.conf.json --disable-host-check",
    "build": "ng build",
    "build:prod": "ng build --configuration production && npm run post-build && npm run sentry:sourcemaps",
    "build:test": "ng build --configuration test && npm run post-build && npm run sentry:sourcemaps",
    "build:accept": "ng build --configuration acc && npm run post-build && npm run sentry:sourcemaps",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint:skip-warnings": "ng lint --quiet",
    "prettier": "npx prettier src/app --check",
    "prettier:fix": "npm run prettier -- --write",
    "format": "npm run prettier:fix && ng lint --fix",
    "test": "ng test --source-map=false",
    "test:unit:watch": "ng test --watch=true",
    "test:e2e:headless": "concurrently \"ng serve --port=4200\" \"wait-on http-get://localhost:4200 && cypress run --headless\" --kill-others --success first",
    "test:e2e": "cypress open",
    "test:coverage": "ng test --code-coverage",
    "test:pipeline": "ng test --no-watch --no-progress --browsers=ChromeHeadlessNoSandbox --karma-config=karma-pipeline.conf.js",
    "test:cc": "npm run test:pipeline",
    "test:run": "cypress run",
    "test:open": "cypress open",
    "compodoc": "npx compodoc -p src/tsconfig.app.json",
    "docs": "npx @compodoc/compodoc -p src/tsconfig.app.json --hideGenerator -d dist/documentation/",
    "extract": "ngx-translate-extract --input ./src --output ./src/assets/i18n.json --clean --sort --format namespaced-json",
    "stylelint": "stylelint '**/*.scss'",
    "setVerdacioRegistry": "npm config set registry https://verdaccio.emendis.nl/",
    "build:stats:test": "ng build --c=test --stats-json && npm run bundle:report",
    "bundle:report": "npx webpack-bundle-analyzer dist/stats.json",
    "post-build": "node ./build/post-build.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org emendis-bv --project marginpar-frontend ./build && sentry-cli sourcemaps upload --org emendis-bv --project marginpar-frontend ./build"
  },
  "pre-commit": [
    "lint",
    "stylelint"
  ],
  "private": true,
  "dependencies": {
    "@angular-eslint/schematics": "^17.5.1",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/localize": "^17.3.12",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/platform-server": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@angular/service-worker": "^17.3.12",
    "@capturum/api": "^17.0.0",
    "@capturum/auth": "^17.0.1",
    "@capturum/builders": "^17.0.9",
    "@capturum/complete": "^17.0.6",
    "@capturum/cypress": "^1.0.0",
    "@capturum/formly": "^17.0.3",
    "@capturum/linting": "^17.0.1",
    "@capturum/login": "^17.0.1",
    "@capturum/powerbi": "^17.0.0",
    "@capturum/shared": "^17.0.1",
    "@capturum/ui": "^17.0.17",
    "@fortawesome/fontawesome-pro": "^5.12.0",
    "@ngx-formly/bootstrap": "^6.2.2",
    "@ngx-formly/core": "^6.2.2",
    "@ngx-formly/primeng": "^6.2.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@ngxs/storage-plugin": "^3.8.1",
    "@ngxs/store": "^3.8.1",
    "@sentry/angular-ivy": "^7.93.0",
    "@sentry/cli": "^2.38.2",
    "@types/chance": "^1.1.6",
    "@types/faker": "^5.5.9",
    "@zxing/browser": "^0.1.1",
    "@zxing/library": "^0.21.3",
    "@zxing/ngx-scanner": "^17.0.4",
    "bootstrap": "^5.3.2",
    "chance": "^1.1.11",
    "chart.js": "^3.8.0",
    "chartjs-plugin-annotation": "^0.5.7",
    "core-js": "^3.39.0",
    "cypress-wait-until": "^1.7.2",
    "file-saver": "^2.0.5",
    "jwt-decode": "^4.0.0",
    "mem-decorator": "^1.0.0",
    "moment": "^2.29.4",
    "ngx-permissions": "^17.1.0",
    "npm-run-all": "^4.1.5",
    "postcss": "^8.4.49",
    "primeicons": "^6.0.1",
    "primeng": "^17.18.4",
    "quill": "^1.3.7",
    "rxjs": "^7.5.7",
    "rxjs-tslint": "^0.1.8",
    "sass": "^1.59.3",
    "ts-loader": "^6.2.1",
    "tslib": "^2.5.0",
    "uuid": "^9.0.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.6",
    "@angular-eslint/builder": "17.4.0",
    "@angular-eslint/eslint-plugin": "17.4.0",
    "@angular-eslint/eslint-plugin-template": "17.4.0",
    "@angular-eslint/schematics": "17.4.0",
    "@angular-eslint/template-parser": "17.4.0",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.3.7",
    "@angular/language-service": "^17.3.12",
    "@angularclass/hmr": "^3.0.0",
    "@commitlint/cli": "^12.1.4",
    "@compodoc/compodoc": "^1.1.26",
    "@ngxs/devtools-plugin": "^3.8.1",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "^12.20.50",
    "@typescript-eslint/eslint-plugin": "7.8.0",
    "@typescript-eslint/parser": "7.8.0",
    "codelyzer": "^6.0.0",
    "concurrently": "^5.3.0",
    "cypress": "^9.7.0",
    "eslint": "^8.57.1",
    "eslint-plugin-prettier": "^5.0.0",
    "eslint-plugin-unused-imports": "^3.2.0",
    "faker": "^5.5.3",
    "husky": "^4.3.8",
    "jasmine-core": "^4.5.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.3.19",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-spec-reporter": "^0.0.36",
    "ngx-spec": "^2.1.5",
    "pre-commit": "^1.2.2",
    "prettier": "^3.2.5",
    "protractor": "~7.0.0",
    "puppeteer": "^19.11.1",
    "run-script-os": "^1.1.6",
    "stylelint": "^14.14.0",
    "ts-node": "~8.3.0",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5",
    "wait-on": "^5.3.0",
    "webdriver-manager": "^12.1.9"
  },
  "husky": {
    "hooks": {
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS",
      "pre-commit": "npm run format"
    }
  }
}
