import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Package } from '@core/models/package.model';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class PackageService extends ApiService<Package> {
  public list$: Observable<MapItem[]>;
  protected endpoint = 'package';

  constructor(
    public apiHttpService: ApiHttpService,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    super(apiHttpService);
  }

  public recordEmptyBuckets(
    data: { package_ids: string[] },
    options?: ListOptions,
  ): Observable<ApiSingleResult<Package>> {
    let url = `/${this.endpoint}/record-empty-buckets`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getList(reload = false, options?: ListOptions): void {
    if (this.list$ === undefined || reload) {
      this.list$ = this.list(options).pipe(
        shareReplay(1),
        map((items) => {
          return ConverterUtil.listToOption(items?.data);
        }),
      );
    }
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  // @TODO: Do we really need to set custom authorized headers here?
  public createPackage(payload: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers = headers.append('Accept', '*/*');

    const options: any = {
      headers,
    };

    return this.http.post(`${environment.baseUrl}/${this.endpoint}`, payload, options);
  }
}
